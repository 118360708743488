import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button'
import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui';
import mapboxgl from 'mapbox-gl';
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { serverconfigs, NO_DATA, customToastId, featureLabels, vendor, GoeFenceRouteColors, trackingDeviceDeclarations } from "../constants.js";
import moment from "moment";
import DateUtils from '../DateUtils.js';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { getIncidentsHistory, fetchHistoryDates, fetchRenderHistory } from '../redux/actions/historyActions.js';
import { showHeatMap } from '../redux/actions/headerActions';
import '../styles/historypanel.scss';
import TrackingServices from '../services/TrackingServices';
import icTrash from '../assets/icon_trash.png';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import playIcon from '../assets/play.svg';
import pauseIcon from '../assets/pause.svg'
import refreshIcon from '../assets/refresh_weather.svg';
import { getTraxmateVendorFromGP } from "../utils/commonUtils";

const toastId = "toast-id";
const textRefresh = 'Refresh';
const textUpdate = "Update";
const displayDateTimeFormat = "MM-DD-YYYY hh:mm A";
const devicesLayerId = 'analytics-device-layer';
const blueColor = '#438CBC'
var heatMapData = []
const heatMapId = `Allheatmap`;
var heatMapWeight = 0.4
class DeviceAnalytics extends Component {
  constructor(props) {
    super(props);
    this.dateUtils = new DateUtils();
    this.trackingService = new TrackingServices();
    this.state = {
      showSpinner: false,
      currentZoom: serverconfigs.zoom,
      startDate: trackingDeviceDeclarations.allDevicesHistoryObj.startDate ? (trackingDeviceDeclarations.allDevicesHistoryObj.startDate) : null,
      endDate: trackingDeviceDeclarations.allDevicesHistoryObj.endDate,
      mapSpinner: false,
      dateTimeBtnTxt: textRefresh,
      selectedCall: "",
      isGeofence: true,
      showDeviceList: [],
      slideVal: 0,
      sliderTicCount: '',
      animateHistoryData: false,
      sliderTotalTimeInMIn: 0,
      interval: trackingDeviceDeclarations.allDevicesHistoryObj.interval,
    };
    this.startDateTime = '';
    this.endDateTime = '';
    this.breadcrumbLayers = [];
    this.animateInterval;
  }

  getHistoryData = async (startTs, endTs) => {
    this.setState({
      dateTimeBtnTxt: textRefresh,
      showDeviceList: [],
      animateHistoryData: false,
    }, () => {
      this.removeAllLayers();
    });
    try {
      let startDateInMs = moment(startTs).valueOf();
      let endDateInMs = moment(endTs).valueOf();
      trackingDeviceDeclarations.allDevicesHistoryObj.geofenceScan = !this.props.clearpolygons ? true : false
      let groupVendor = getTraxmateVendorFromGP();
      if(groupVendor && groupVendor == '') {
        return
      }
      const response = await this.trackingService.getDeviceHistoryData(groupVendor, startDateInMs, endDateInMs, !this.props.clearpolygons ? true : false, "all");
      if (response.status === 200 && response.data && response.data.vehicles && response.data.vehicles.length > 0) {
        const allDevicesList = response.data.vehicles;
        const updatedDevicesList = this.getLeftPannelData(allDevicesList);
        if (updatedDevicesList && updatedDevicesList.length) {
          var diff = moment.duration(moment(this.state.endDate).diff(moment(this.state.startDate)));
          var sliderTotalTimeInMIn = diff.asMinutes();
          var sliderInterval = trackingDeviceDeclarations.allDevicesHistoryObj.interval;
          var ticCount = Math.ceil(sliderTotalTimeInMIn / sliderInterval);
          console.log(":::: diff ::: " + diff + " ::: sliderTotalTimeInMIn ::: " + sliderTotalTimeInMIn + " ::: sliderInterval ::: " + sliderInterval + " ::: ticCount ::: " + ticCount)
          this.setState({
            mapSpinner: false,
            showDeviceList: this.getFilteredDeviceList(updatedDevicesList),
            interval: sliderInterval,
            sliderTotalTimeInMIn: sliderTotalTimeInMIn,
            sliderTicCount: ticCount,
          }, () => {
            this.setPropsData();
            this.populateDevicesOnMap(allDevicesList, true);
            // this.displayDevicesList(false);
          });
        } else {
          this.setState({
            sliderTicCount: 0,
            mapSpinner: false,
          })
        }
      } else {
        this.setState({
          sliderTicCount: 0,
          mapSpinner: false,
        })
      }
    } catch (error) {
      this.setState({
        mapSpinner: false,
        sliderTicCount: 0,
      });
      console.log('error: ', error);
    }
  }

  populateDevicesOnMap = (allDevicesList, callSetBouns) => {
    heatMapData = [];
    let bounds = [];
  
    // Remove geofence layers
    this.removeGeofenceLayers();
  
    // Filter out devices with count <= 0
    const filteredDevicesList = allDevicesList.filter(device => device.count > 0);
  
    if (this.props.showMap) {
      filteredDevicesList.reverse().forEach((device, index) => {
        if (!device.positions || device.positions.length === 0) {
          return;
        }
  
        // Get color for line
        const orangeColor = device.color;
  
        // Get coordinates for line
        let newCoordinates;
        if (trackingDeviceDeclarations.allDevicesHistoryObj.geofenceScan) {
          const positionsByGeofence = device.positions.reduce((acc, curr) => {
            for (const geofence of curr.geofences) {
              acc[geofence] = [...(acc[geofence] || []), curr];
            }
            return acc;
          }, {});
          Object.entries(positionsByGeofence).forEach(([geofence, positions], index) => {
            console.log(`Geofence ${geofence}: ${positions.length}`);
            newCoordinates = positions.map(position => [position.coordinates[1], position.coordinates[0]]);
            heatMapData.push(...newCoordinates);
            if (this.props.isHeatMap) {
              this.showDevicesHeatMap();
            } else {
              this.addLine(device, newCoordinates, orangeColor, index);
            }
            const oriLngLat = new mapboxgl.LngLat(newCoordinates[newCoordinates.length - 1][0], newCoordinates[newCoordinates.length - 1][1]);
            const lngLat = new mapboxgl.LngLat(newCoordinates[0][0], newCoordinates[0][1]);
            bounds.push(lngLat);
            bounds.push(oriLngLat);
          });
        } else {
          newCoordinates = device.positions.map(position => [position.coordinates[1], position.coordinates[0]]);
          heatMapData.push(...newCoordinates);
          if (this.props.isHeatMap) {
            this.showDevicesHeatMap();
          } else {
            this.addLine(device, newCoordinates, orangeColor, index);
          }
          const oriLngLat = new mapboxgl.LngLat(newCoordinates[newCoordinates.length - 1][0], newCoordinates[newCoordinates.length - 1][1]);
          const lngLat = new mapboxgl.LngLat(newCoordinates[0][0], newCoordinates[0][1]);
          bounds.push(lngLat);
          bounds.push(oriLngLat);
        }
        this.toggleDeviceLayersVisibility(device.id, device.toggleStatus)
      });
  
      if (bounds.length && callSetBouns) {
        this.setBounds(bounds);
      }
    }
  };

  setBounds = (bounds, zoomLevel) => {
    var bbox = new mapboxgl.LngLatBounds();
    if (this.props.map) {
      if (bounds && bounds.length && bounds.length === 1) {
        this.props.map.flyTo({
          center: bounds[0],
          zoom: zoomLevel,
          bearing: 0,
          speed: 1.2, // make the flying slow
          curve: 4, // change the speed at which it zooms out
          easing: function (t) { return t; }
        });
      } else if (bounds && bounds.length && bounds.length > 1) {
        bounds.forEach((coordinate) => {
          bbox.extend(coordinate);
        });
        this.props.map.fitBounds(bbox, {
          maxZoom: trackingDeviceDeclarations.defaultZoomLevel,
          padding: { top: 120, bottom: 100, left: 50, right: 75 }
        });
      }
    }
  }

  getRandomColorFromArray = (array, index) => {
    var randomIndex = Math.floor(Math.random() * array.length);
    return array[index];
  }

  addLine = (device, newCoordinates, orangeColor, index) => {
    let layers = this.breadcrumbLayers;
    const lineId = `${index}circles_${device.id}`;
    const polylineId = `${index}polyline_${device.id}`;
    layers.push(lineId, polylineId);
    this.breadcrumbLayers = layers;

    if (!this.props.map.getSource(lineId)) {
      this.props.map.addSource(lineId, { type: 'geojson', data: { type: 'Feature', properties: {}, geometry: { type: 'LineString', coordinates: newCoordinates } } })
        .addLayer({ id: lineId, type: 'circle', source: lineId, paint: { 'circle-radius': 3, 'circle-color': orangeColor } })
    } else {
      this.props.map.getSource(lineId).setData(newCoordinates);
    }
    if (!this.props.map.getSource(polylineId)) {
      this.props.map.addSource(polylineId, { type: 'geojson', data: { type: 'Feature', properties: {}, geometry: { type: 'LineString', coordinates: newCoordinates } } })
        .addLayer({ id: polylineId, type: 'line', source: polylineId, layout: { 'line-join': 'round', 'line-cap': 'round' }, paint: { 'line-width': 2, 'line-color': orangeColor } })
    } else {
      this.props.map.getSource(lineId).setData(newCoordinates);
    }
  }

  toggleLineLayers = (visibility) => {
    if (this.breadcrumbLayers.length) {
      for (const layerID of this.breadcrumbLayers) {
        this.props.map.setLayoutProperty(layerID, 'visibility', visibility);
      }
    }
  };

  toggleDeviceLayersVisibility = (deviceId, visibility) => {
    if (this.breadcrumbLayers.length) {
      for (const layerID of this.breadcrumbLayers) {
        if(layerID.includes(deviceId)) {
            if(visibility) {
              this.props.map.setLayoutProperty(layerID, 'visibility', 'visible');
            } else {
              this.props.map.setLayoutProperty(layerID, 'visibility', 'none');
            }
        }
      }
    }
  };

  toggleBreadcrumbLayers = (deviceId, visibility) => {
    if (this.props.isHeatMap) return;
    this.toggleDeviceLayersVisibility(deviceId, visibility)
    this.updateToggleStatusInDevice(deviceId)
  };
 
  updateToggleStatusInDevice = (deviceId) => {
    const { showDeviceList } = this.state;
    const updatedDevices = showDeviceList.map((device) => {
      if (device.id === deviceId) {
        return {
          ...device,
          toggleStatus: !device.toggleStatus,
        };
      }
      return device;
    });

    this.setState({
      showDeviceList: updatedDevices,
    }, () => {
      trackingDeviceDeclarations.allDevicesHistoryObj.deviceData = this.state.showDeviceList
    });
  }

  resetDevicesStatus = () => {
    const { showDeviceList } = this.state;
    const updatedDevices = showDeviceList.map((device) => {
      return {
        ...device,
        toggleStatus: true,
      };
    });
    this.setState({
      showDeviceList: updatedDevices,
    }, () => {
      trackingDeviceDeclarations.allDevicesHistoryObj.deviceData = this.state.showDeviceList
    });
  }

  prepareHeatMapData = () => {
    if (heatMapData.length > 0) {
      return {
        type: 'FeatureCollection',
        features: heatMapData.map(([lng, lat]) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          }
        }))
      };
    }
  };
  

  hideReqSpinner = () => {
    this.setState({
      mapSpinner: false
    })
  }

  // clear all cluster related data from map
  removeAllLayers() {
    this.clearDeviceLayers();
    if (this.props.showMap) {
      let mpSource = this.props.map.getSource("customSource");
      if (typeof mpSource !== "undefined") {
        this.props.map.removeSource("customSource");
      }
    }
    this.setState({ selectedCall: "" });
    this.removeGeofenceLayers();
    this.removeDevicesHeatMap();
    heatMapData = []
  }

  removeGeofenceLayers = () => {
    let breadCrumbList = this.breadcrumbLayers;
    if (breadCrumbList && breadCrumbList.length) {
      for (var i = 0; i < breadCrumbList.length; i++) {
        if (this.props.map.getLayer(breadCrumbList[i])) {
          this.props.map.removeLayer(breadCrumbList[i]);
          this.props.map.removeSource(breadCrumbList[i]);
        }
      }
    }
    this.breadcrumbLayers = [];
    heatMapData = []
  }

  validateStateAndEndDate = () => {
    let validateMsg = this.dateUtils.isValidDateTime(this.state.startDate, this.state.endDate);
    if (validateMsg === 'valid') {
      return true;
    } else {
      toast.error(validateMsg, { toastId: toastId });
    }
    return false;
  }

  refreshBtnClick = () => {
    if (this.validateStateAndEndDate()) {
      this.handleLocalTime();
      this.setState({ animateHistoryData: false })
      clearInterval(this.animateInterval);
    }
  }

  // store the state value status in props, which will be used when tab is switched or theam changed
  setPropsData = () => {
    this.removeAllLayers();
    trackingDeviceDeclarations.allDevicesHistoryObj.startDate = this.state.startDate;
    trackingDeviceDeclarations.allDevicesHistoryObj.endDate = this.state.endDate;
    // trackingDeviceDeclarations.allDevicesHistoryObj.isGeofence = this.state.isGeofence
    trackingDeviceDeclarations.allDevicesHistoryObj.deviceData = this.state.showDeviceList
    trackingDeviceDeclarations.allDevicesHistoryObj.interval = this.state.interval
    trackingDeviceDeclarations.allDevicesHistoryObj.slideVal = this.state.slideVal
    trackingDeviceDeclarations.allDevicesHistoryObj.sliderTotalTimeInMIn = this.state.sliderTotalTimeInMIn
  }

  componentDidMount() {
    if (trackingDeviceDeclarations.allDevicesHistoryObj.deviceData.length) {
      this.populateDevicesOnMap(trackingDeviceDeclarations.allDevicesHistoryObj.deviceData, true);
      this.setState({
        showDeviceList: trackingDeviceDeclarations.allDevicesHistoryObj.deviceData,
        dateTimeBtnTxt: textRefresh,
        sliderTotalTimeInMIn: trackingDeviceDeclarations.allDevicesHistoryObj.sliderTotalTimeInMIn,
        slideVal: Number(trackingDeviceDeclarations.allDevicesHistoryObj.rangeValue),
        sliderTicCount: Math.ceil(trackingDeviceDeclarations.allDevicesHistoryObj.sliderTotalTimeInMIn / trackingDeviceDeclarations.allDevicesHistoryObj.interval),
        interval: trackingDeviceDeclarations.allDevicesHistoryObj.interval,
      }, () => {
        // this.displayDevicesList(false);
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadMap !== this.props.loadMap && this.props.showMap) {
      if (trackingDeviceDeclarations.allDevicesHistoryObj.deviceData.length) {
        this.populateDevicesOnMap(trackingDeviceDeclarations.allDevicesHistoryObj.deviceData, true);
        // this.displayDevicesList(false);
      } else {
        // this.displayDevicesList(true);
      }
      if (this.props.isHeatMap && this.props.map) {
        this.showDevicesHeatMap();
      }
    }
    if (prevProps.isHeatMap !== this.props.isHeatMap) {
      if (this.props.isHeatMap && this.props.map) {
        this.showDevicesHeatMap();
      } else if (!this.props.isHeatMap) {
        this.removeDevicesHeatMap();
        this.populateDevicesOnMap(this.state.showDeviceList, false);
      }
    }
  }

  componentWillUnmount() {
    let that = this;
    if (that.props.showMap) {
      this.removeAllLayers();
    }
    if (this.animateInterval) clearInterval(this.animateInterval);
  }

  handleLocalTime = () => {
    this.setState({
      mapSpinner: true,
    }, () => {
      if (this.validateStateAndEndDate()) {
        this.clearSliderData(() => {
          this.getHistoryData(this.state.startDate, this.state.endDate);
        });
      }
    })
  }

  setEndDate = (date) => {
    this.setState({
      endDate: date,
      dateTimeBtnTxt: date ? textUpdate : textRefresh
    });
    // setTimeout(() => this.updateDateBtnText(), 500);

  }

  setStartDate = (date) => {
    this.setState({
      startDate: date
    });
    // setTimeout(() => this.updateDateBtnText(), 500);
  }

  updateDateBtnText = () => {
    if (this.breadcrumbLayers.length) {
      this.setState({
        dateTimeBtnTxt: textUpdate
      });
    } else {
      this.setState({
        dateTimeBtnTxt: textRefresh
      });
    }
  }

  getLeftPannelData = (obj) => {
    return obj.filter(device => device.count > 0);
  }

  
  addClusteredResources = (data, layerName) => {
    if (this.props.loadMap && this.props.map) {
      // add a clustered GeoJSON source for a sample set of incidents
      if (!this.props.map.getSource(layerName)) {
        this.props.map.addSource(layerName, {
          'type': 'geojson',
          'data': data
        });

        // circle and symbol layers for rendering individual incidents (unclustered points)
        this.props.map.addLayer({
          'id': layerName,
          'type': 'symbol',
          'source': layerName,
          layout: {// Make the layer visible by default.
            "text-field": ['get', 'textField'],
            "text-anchor": "center",
            "text-size": 12,
            "text-offset": [-0.1, -2],
            "text-justify": "center",
            'visibility': 'visible',
            "icon-image": [
              "match",
              [
                "get",
                "status"
              ],
              ["active"], 'device_active',
              ["inactive"], 'device_inactive',
              'device_active'
            ],
            "icon-size": 0.8,
            'icon-anchor': 'bottom'
          },
          "paint": {
            "text-color": "#000"
          }

        });
        this.props.map.on("mousemove", layerName, () => {
          this.props.map.getCanvas().style.cursor = "pointer";
        });
        this.props.map.on("mouseleave", layerName, () => {
          if (this.props.isMeasurementOn) {
            this.props.map.getCanvas().style.cursor = 'crosshair';
          } else {
            this.props.map.getCanvas().style.cursor = '';
          }
        });
      } else { // If the layer has already been added then just update it with the new data
        this.props.map.getSource(layerName).setData(data);
      }
    }
  }

  removeLayer = (layerName) => {
    if (this.props.map.getLayer(layerName)) {
      this.props.map.removeLayer(layerName);
    }
  }

  removeSource = (layerName) => {
    if (this.props.map.getSource(layerName)) {
      this.props.map.removeSource(layerName);

    }
  }

  clearDeviceLayers = () => {
    if (this.props.loadMap && this.props.map) {
      this.removeLayer(devicesLayerId);
      this.removeSource(devicesLayerId);
    }
  }

  getFilteredDeviceList = (list) => {
    list.map((device, ind) => {
      device.color = GoeFenceRouteColors[ind],
      device.toggleStatus = true
    })
    return list;
  }

  clearBtnClick = () => {
    if (trackingDeviceDeclarations.allDevicesHistoryObj.startDate && trackingDeviceDeclarations.allDevicesHistoryObj.endDate) {
      this.clearSliderData(() => {
        this.setState({
          showDeviceList: [],
          startDate: null,
          endDate: null,
          dateTimeBtnTxt: textRefresh,
        }, () => {
          this.removeAllLayers();
          trackingDeviceDeclarations.allDevicesHistoryObj.startDate = null;
          trackingDeviceDeclarations.allDevicesHistoryObj.endDate = null;
          trackingDeviceDeclarations.allDevicesHistoryObj.deviceData = [];
          this.props.showHeatMap(false);
        })
      });
    }
  }

  calculateHeatMapWeight = () => {
  //   let duration = this.state.interval
  //   let numberOfDevices = this.state.showDeviceList.length

  //   let ff = 10

  //  var weight =  (ff / (duration * numberOfDevices));
  //   if(weight > 1) {
  //     weight = 1
  //   }
  //   return weight

    let noOfDays = this.state.sliderTotalTimeInMIn / 1440

   var weight =  (0.03 + (0.003 * noOfDays));
    if(weight > 1) {
      weight = 1
    }
    return weight
  }

  showDevicesHeatMap = () => {
    heatMapWeight = this.calculateHeatMapWeight();
    // heatMapWeight =  (20 / (30 * 5));
    // console.log(":::::: heatMapWeight :::::: " + heatMapWeight)
    const geojson = this.prepareHeatMapData()
    this.resetDevicesStatus();
    this.toggleLineLayers('none');
    if (!this.props.map.getSource(heatMapId)) {
      this.props.map.addSource(heatMapId, {
        'type': 'geojson',
        'data': geojson
      });
      this.props.map.addLayer(
        {
          'id': heatMapId,
          'type': 'heatmap',
          'source': heatMapId,
          'maxzoom': 22,
          'paint': {
            // 'heatmap-weight': [
            //   'interpolate',
            //   ['linear'],
            //   0.5,
            //   0,
            //   0,
            //   100,
            //   1
            // ],
            
						'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba( 43, 131, 186,0)',
              0.1,
              'rgba( 43, 131, 186,0.5)',
              0.2,
              'rgb( 145, 203, 169 )',
              0.4,
              'rgb( 222, 242, 180 )',
              0.6,
              'rgb( 255, 223, 154 )',
              0.8,
              'rgb( 246, 144, 83 )',
              1,
              'rgb( 215, 25, 28 )'
              ],
              'heatmap-weight':heatMapWeight,
              'heatmap-intensity': [
                'interpolate',
                ['exponential',
                4],
                ['zoom'],
                0,
                0.000001,
                14,
                268.435456],
              // Adjust the heatmap radius by zoom level
              'heatmap-radius': 40,
              // Transition from heatmap to circle layer by zoom level
              'heatmap-opacity': 0.5
  
					}

        },
        'Country Labels'
      );
    } else {
      this.props.map.getSource(heatMapId).setData(geojson);
    }
  }

  removeDevicesHeatMap = () => {
    if (this.props.map && this.props.map.getSource(heatMapId)) {
      this.props.map.removeLayer(heatMapId);
      this.props.map.removeSource(heatMapId);
    }
  }

  getDayFromDate = (value) => {
    return moment(value).format('DD');
  }

  displayActiveHours = (displayDateTime) => {
    let activeHourVal;
    const diff = moment.duration(moment(this.state.endDate).diff(moment(this.state.startDate)));
    const diffInHrs = diff.asHours();
    if (diffInHrs > 24) {
      activeHourVal = displayDateTime ? moment(new Date(displayDateTime)).format('MM-DD-YYYY hh:mm A') : '';
    } else {
      activeHourVal = displayDateTime ? moment(new Date(displayDateTime)).format('hh:mm A') : '';
    }
    this.setState({
      activeHour: activeHourVal
    })
  }

  handleSliderData = (historyData, sliderValue) => {
    this.startDateTime = moment(this.state.startDate).valueOf()
    this.endDateTime = moment(this.state.endDate).valueOf()
    let newStartDateTime = this.startDateTime + (sliderValue * this.state.interval * 60 * 1000);
    let newEndDateTime = newStartDateTime + (this.state.interval * 60 * 1000);
    // When interval value exceeds the end time set it with end time.
    if (newStartDateTime > this.endDateTime) {
      newStartDateTime = this.endDateTime;
    }
    if (newEndDateTime > this.endDateTime) {
      newEndDateTime = this.endDateTime;
    }
    this.displayActiveHours(newStartDateTime);

    let initialFC = this.filterDevicesBasedOnTime(
      historyData,
      newStartDateTime,
      newEndDateTime
    );
    this.populateDevicesOnMap(initialFC, false);
  }

  filterDevicesBasedOnTime = (collection, newStartDateTime, newEndDateTime) => {
    return collection.map(device => {
      // Filter positions based on time range
      const newPositionsArray = device.positions.filter(positionObj => {
        return (positionObj.epoch *1000) >= newStartDateTime && (positionObj.epoch *1000) <= newEndDateTime;
      });
      const formattedStartDate = moment(newStartDateTime).format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = moment(newEndDateTime).format('YYYY-MM-DD HH:mm:ss');

      console.log(":: newPositionsArray ::: " + newPositionsArray.length + ":::: at ::: " + formattedStartDate + " ::: end ::: " + formattedEndDate)
      // Create new device object with filtered positions
      return {
        ...device,
        positions: newPositionsArray,
      };
    }).filter(device => {
      // Filter out devices without positions after filtering
      return device.positions.length > 0;
    });
  };
  

  handleSliderChange = (event, val) => {
    if (this.state.showDeviceList) {
      let slideVal;
      if (event && event.target) {
        slideVal = event.target.value;
      } else {
        slideVal = this.state.slideVal;
      }
      this.setState({
        slideVal: slideVal
      }, () => {
        trackingDeviceDeclarations.allDevicesHistoryObj.interval = this.state.interval
        trackingDeviceDeclarations.allDevicesHistoryObj.rangeValue = this.state.slideVal
        trackingDeviceDeclarations.allDevicesHistoryObj.sliderTotalTimeInMIn = this.state.sliderTotalTimeInMIn
        this.handleSliderData(this.state.showDeviceList, Number(this.state.slideVal));
      });
    }
  }

  animateIntervalSlider = () => {
    this.setState({
      animateHistoryData: !this.state.animateHistoryData
    }, () => {      
      if (this.state.animateHistoryData) {
        this.animateInterval = setInterval(() => {
          let loopCount = this.state.sliderTicCount;
          let presentSliderPosition = Number(this.state.slideVal);
          if (presentSliderPosition === loopCount) {
            this.setState({ slideVal: 0 }, () => {
              this.handleSliderChange(null, this.state.interval);
            });
          } else {
            this.setState({ slideVal: (presentSliderPosition + 1)}, () => {
              this.handleSliderChange(null, this.state.interval);
            });
          }
        }, 1000);
      } else {
        let obj = {
          target: {
            value: Number(this.state.slideVal)
          }
        }
        this.setState({
          animateHistoryData: false
        })
        clearInterval(this.animateInterval);
        this.handleSliderChange(obj, this.state.interval);
      }
    })
  }

  resetIntervalSlider = () => {
    const sliderTicCount = this.state.sliderTicCount
    const sliderTotalTimeInMIn = this.state.sliderTotalTimeInMIn
    
    this.clearSliderData(() => {
      this.setState({
        sliderTicCount: sliderTicCount ,
        sliderTotalTimeInMIn: sliderTotalTimeInMIn
      }, () => {
        trackingDeviceDeclarations.allDevicesHistoryObj.interval = this.state.interval,
        trackingDeviceDeclarations.allDevicesHistoryObj.sliderTotalTimeInMIn = this.state.sliderTotalTimeInMIn
      })
      this.startDateTime = moment(this.state.startDate).valueOf()
      this.displayActiveHours(this.startDateTime);
      this.populateDevicesOnMap(this.state.showDeviceList, true);
    });
  }

  handleSliderInterval = (e) => {
    let interval = parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value);
    if (interval !== '' && interval <= this.state.sliderTotalTimeInMIn) {
      this.setState({
        interval: interval ,
        slideVal: 1,
        sliderTicCount: Math.ceil(this.state.sliderTotalTimeInMIn / interval)
      }, () => {
        // this.removeDevicesHeatMap()
        this.handleSliderChange(null, interval);
      })
    }
    else if (interval === '') {
      this.setState({
        slideVal: 0,
        interval: ''
      })
    }
  }

  clearSliderData = (callback) => {
    this.setState({ 
      animateHistoryData: false, 
      slideVal: 0,
      rangeValue: 0,
      sliderTicCount: 0,
      interval : trackingDeviceDeclarations.allDevicesHistoryObj.defaultInterval,
      activeHour: '',
     },() => {
      trackingDeviceDeclarations.allDevicesHistoryObj.interval = trackingDeviceDeclarations.allDevicesHistoryObj.defaultInterval
      trackingDeviceDeclarations.allDevicesHistoryObj.rangeValue = 0
      trackingDeviceDeclarations.allDevicesHistoryObj.sliderTotalTimeInMIn = 0
      clearInterval(this.animateInterval);
      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  render() {
    const { startDate, endDate, dateTimeBtnTxt } = this.state;
    var timezone = moment.tz(moment.tz.guess()).zoneAbbr();

    return (
      <>
        {this.state.mapSpinner ?
          <Spinner animation="border" variant="primary" className='spinner-map'>
            <span className="sr-only"></span>
          </Spinner>
          : ''}
        <div className="top-container">
          <div className="datePick">
            <div className='date-range-picker'>
              <div className='d-flex align-items-baseline'>
                <FaRegCalendarAlt className='calMargin'>Cal</FaRegCalendarAlt>
                <div className="date-range-control d-flex">
                  <div className="date-range-control-label"><p>From:</p><p>To:</p></div>
                  <EuiDatePickerRange
                    fullWidth={false}
                    startDateControl={
                      <EuiDatePicker
                        dateFormat={displayDateTimeFormat}
                        selected={startDate}
                        onChange={this.setStartDate}
                        startDate={startDate}
                        endDate={endDate}
                        allowSameDay={true}
                        isInvalid={endDate && startDate >= endDate}
                        aria-label="Start date"
                        timeIntervals={15}
                        showTimeSelect
                      />
                    }
                    endDateControl={
                      <EuiDatePicker
                        dateFormat={displayDateTimeFormat}
                        selected={endDate}
                        onSelect={this.setEndDate}
                        startDate={startDate}
                        endDate={endDate}
                        allowSameDay={true}
                        isInvalid={startDate && endDate && startDate >= endDate}
                        aria-label="End date"
                        timeIntervals={15}
                        showTimeSelect
                      />
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <img className='file-trash hand-cursor delete-history clear-button' title='Delete History' src={icTrash} onClick={this.clearBtnClick} />
                <div>
                  <Button variant="outline-primary" size="sm" onClick={this.refreshBtnClick} className='update-button'>{this.state.dateTimeBtnTxt}</Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`d-flex align-items-center play-history mt-1 position-relative ${this.state.sliderTicCount > 0 ? '' : 'disabledbutton'}`}>
              <img className='refresh-icon hand-cursor' src={refreshIcon} onClick={this.resetIntervalSlider} />

              <div className='animate-slider hand-cursor'
                onClick={this.animateIntervalSlider}>
                <img src={playIcon} className={`${this.state.animateHistoryData ? 'd-none' : 'play-icon'}`} />
                <img src={pauseIcon} className={`${this.state.animateHistoryData ? 'pause-icon' : 'd-none'}`} />
              </div>
              <input
                id="range"
                type="range"
                min={0}
                max={this.state.sliderTicCount}
                value={this.state.slideVal}
                onChange={this.handleSliderChange}
                disabled={this.state.showDeviceList.length <= 0}
                step="1"
              />
            </div>

            <div
              className="range-value"
              id="rangeV"
            >
              {this.state.activeHour ? this.state.activeHour : ''}
            </div>
            <br />
            <div className="info d-flex align-items-center">
              <FaRegClock className="clock" >Clock</FaRegClock>
              Interval <input id="interval" type='number' className='form-control interval' min={1} max={this.state.sliderTotalTimeInMIn}
                value={this.state.interval ? this.state.interval : trackingDeviceDeclarations.allDevicesHistoryObj.defaultInterval}
                onChange={this.handleSliderInterval}
                disabled={this.state.showDeviceList.length <= 0} /> min
            </div>
            <hr style={{ marginBottom: 0 + "em" }} />
          </div>
        </div>
        <div className="dataContainer device-analytics">
          <div className="listView">
            {this.state.showDeviceList && this.state.showDeviceList.length ?
              this.state.showDeviceList.map((device, index) => {
                return (
                  <div key={device.id} className="device-list" >
                    <div>
                      <div className="d-flex align-center justify-content-start">
                        <div className="colorBox" style={{backgroundColor: `${index === 0 ? blueColor : device.color}`}}>
                          
                        </div>
                        <div className="ms-2">
                          <div className="device-name">{device.label}</div>
                          <div className="device-address">{}</div>
                        </div>
                        {
                          device.toggleStatus ? <FaEye className="eyeIcon" onClick={() => this.toggleBreadcrumbLayers(device.id, false)}/> :  <FaEyeSlash className="eyeIcon" onClick={() => this.toggleBreadcrumbLayers(device.id, true)}/>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
              : <div className='no-data-found'>No Data Found</div>
            }
          </div>

        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    incidentsHistory: state.analytics.incidentsHistory,
    isLoading: state.analytics.isLoading,
    dates: state.dates.dates,
    render: state.historyRender.render,
    error: state.analytics.error,
    devicesList: state.devicesList.devicesList,
    loadMap: state.loadMap.mapLoaded,
    isHeatMap: state.heatmapStatus.isHeatMap,
    intervals: state.historyIntervals.intervals,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIncidentsHistory: (startTs, EndTs) => dispatch(getIncidentsHistory(startTs, EndTs)),
    fetchHistoryDates: (val) => dispatch(fetchHistoryDates(val)),
    fetchRenderHistory: (val) => dispatch(fetchRenderHistory(val)),
    showHeatMap: (val) => dispatch(showHeatMap(val)),
    fetchHistorySliderValue: (val) => dispatch(fetchHistorySliderValue(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceAnalytics);
