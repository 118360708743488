/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const primaryRegion = {
    "baseUrl":'https://apis.qa.smartresponse.net',
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:600468fa-c5c2-4a2b-80ef-82dc92a4a1bd",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_glN3JcUO9",
    "aws_user_pools_web_client_id": "6c6td0lds16e9akqmbau1s3l1o",
    "oauth": {},
    "aws_content_delivery_bucket": "geoservices-qa",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d3f39yot6i4svi.cloudfront.net",
    "aws_notification_host": "a1cup89kls17rg-ats.iot.us-west-2.amazonaws.com",
    "aws_notification_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://i495w7a58b.execute-api.us-west-2.amazonaws.com/qa",
            "region": "us-west-2"
        }
    ]
};

export const secondaryRegion = {
    "baseUrl":'https://apis.qa-ohio.smartresponse.net',
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b2a22ba9-37b6-4b58-ae21-dedd35a0f458",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KIPaasM6b",
    "aws_user_pools_web_client_id": "7qnkt9blv0rfgr2ct44icqbdsh",
    "aws_notification_host": "a1cup89kls17rg-ats.iot.us-east-1.amazonaws.com",
    "aws_notification_region": "us-east-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "geoservices-qavirginia",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d170cxnishekri.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://dp8wbm7e5h.execute-api.us-east-1.amazonaws.com/qavirginia",
            "region": "us-east-1"
        }
    ]
};

